import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../firebase/firebase-config";
import { useAuth } from "../contexts/AuthContext";
import {
  Toolbar,
  IconButton,
  SwipeableDrawer,
  ListItem,
  ListItemIcon,
  List,
  Typography,
  ListItemButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BsRobot } from "react-icons/bs";
import { TiTimesOutline } from "react-icons/ti";
import { CiMenuKebab } from "react-icons/ci";
import { SiFacepunch } from "react-icons/si";
import "../components/App.css";
import Lottie from "lottie-react";
import animationData from "../assets/tricube.json";
import FeedbackModal from "./feedback";
import { BsFillChatRightTextFill } from "react-icons/bs";

const actions = [
  {
    icon: <BsRobot />,
    name: "MGMT",
    subActions: [
      { name: "HR", path: "/update-Record" },
      { name: "APPS", path: "/apps" },
      { name: "PROFILE", path: "/asl" },
      { name: "STATS", path: "/stats" },
    ],
  },
  {
    icon: <SiFacepunch />,
    name: "PAYROLL",
    subActions: [
      { name: "PUNCHES", path: "/punch" },
      { name: "SUMMARY", path: "/summary" },
      { name: "MY TIME", path: "/mytime" },
      { name: "MY STUBS", path: "/myStubs" },
      { name: "STUBS", path: "/stubs" },
      { name: "ROSTER", path: "/roster" },
    ],
  },
];

const Nav = () => {
  const { user, dispatch } = useAuth();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [filteredActions, setFilteredActions] = useState([]);
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const toggleFeedbackModal = () => {
    setFeedbackOpen(!feedbackOpen);
  };

  const [openSubMenu, setOpenSubMenu] = useState("");

  const handleSubMenuClick = (name) => {
    setOpenSubMenu(openSubMenu === name ? "" : name);
  };

  const logout = useCallback(() => {
    signOut(auth)
      .then(() => {
        dispatch({ type: "LOGOUT" });
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  }, [dispatch]);

  const AUTO_LOGOUT_DURATION = 60 * 60 * 1000;

  useEffect(() => {
    if (!user) return;

    const timer = setTimeout(() => {
      logout();
    }, AUTO_LOGOUT_DURATION);

    return () => {
      clearTimeout(timer);
    };
  }, [user, AUTO_LOGOUT_DURATION, logout]);

  const roleRestrictions = {
    Global: ["MY TIME", "MY STUBS", "PROFILE"],
    Regional: ["MY TIME", "MY STUBS", "PROFILE", "STATS"],
    Accounting: ["MY TIME", "MY STUBS", "PROFILE", "STATS"],
    Manager: ["MY TIME", "MY STUBS", "PROFILE", "STATS"],
    OtherAdmin: ["MY TIME", "MY STUBS", "PROFILE", "STATS"],
    AGM: ["MY TIME", "STUBS", "MY STUBS", "PROFILE", "STATS"],
    Sales: [
      "HR",
      "APPS",
      "PUNCHES",
      "Reports",
      "ROSTER",
      "STUBS",
      "MY STUBS",
      "PROFILE",
      "STATS",
    ],
    Staff: [
      "HR",
      "APPS",
      "PUNCHES",
      "ROSTER",
      "STUBS",
      "SUMMARY",
      "STATS",
    ],
    Property: [
      "HR",
      "APPS",
      "PUNCHES",
      "ROSTER",
      "MY TIME",
      "Applications",
      "STUBS",
      "MY STUBS",
      "PROFILE",
      "STATS",
    ],
  };

  useEffect(() => {
    const updateFilteredActions = (role) => {
      if (role in roleRestrictions) {
        const restrictedNames = roleRestrictions[role];
        const filtered = actions
          .map((action) => {
            const filteredSubActions = action.subActions.filter(
              (subAction) => !restrictedNames.includes(subAction.name)
            );
            return { ...action, subActions: filteredSubActions };
          })
          .filter((action) => action.subActions.length > 0); // filter out actions without visible sub-actions
        setFilteredActions(filtered);
      } else if (["Global"].includes(role)) {
        // Roles that are not specified in the roleRestrictions object will have access to all actions
        setFilteredActions(actions);
      } else {
        // If the role doesn't match any predefined role, filteredActions is an empty array
        setFilteredActions([]);
      }
    };

    if (user) {
      const role = user.photoURL;
      updateFilteredActions(role);
    } else {
      setFilteredActions([]);
    }
  }, [user]);

  useEffect(() => {
    const handleResize = () => {
      if (!isMobile) {
        setDrawerOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const handleNavigation = (path) => {
    navigate(path);
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const handleClickLogOut = async () => {
    await signOut(auth);
    dispatch({ type: "LOGOUT" });
    navigate("/login");
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const renderDrawer = () => (
    <SwipeableDrawer
      open={drawerOpen}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      PaperProps={{
        style: { backgroundColor: "transparent", boxShadow: "none" },
      }}
    >
      <List
        className={`l-navbar ${!drawerOpen ? "l-navbar-closed" : ""}`}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <div sx={{ flexGrow: 1 }}>
          {filteredActions.map((action) => (
            <div key={action.name}>
              <ListItemButton
                onClick={() => handleSubMenuClick(action.name)}
                sx={{
                  backgroundColor:
                    openSubMenu === action.name ? "#fcfcfc" : "transparent",
                }}
              >
                <div className="icon-container">
                  <ListItemIcon>
                    {React.cloneElement(action.icon, { className: "nav-icon" })}
                  </ListItemIcon>
                  <Typography
                    variant="caption"
                    component="div"
                    className={`icon-title ${
                      action.path === window.location.pathname ? "active" : ""
                    }`}
                    sx={{
                      fontWeight: "600",
                      fontSize: "0.81rem",
                      fontFamily: "Nunito",
                      color: "#202426",
                    }}
                  >
                    {action.name}
                  </Typography>
                </div>
              </ListItemButton>
              {openSubMenu === action.name &&
                action.subActions &&
                action.subActions.map((subAction) => (
                  <ListItemButton
                    onClick={() => handleNavigation(subAction.path)}
                    className={
                      subAction.path === window.location.pathname
                        ? "active"
                        : ""
                    }
                  >
                    <div className="icon-container">
                      <Typography
                        variant="caption"
                        component="div"
                        className={`icon-title ${
                          subAction.path === window.location.pathname
                            ? "active"
                            : ""
                        }`}
                        sx={{
                          fontWeight: "600",
                          fontSize: "0.81rem",
                          fontFamily: "Nunito",
                          color: "#202426",
                          paddingTop: "9px",
                          paddingBottom: "9px",
                        }}
                      >
                        {subAction.name}
                      </Typography>
                      {/* <ListItemIcon>
                        <BiDotsHorizontalRounded className="nav-icon" />
                      </ListItemIcon> */}
                    </div>
                  </ListItemButton>
                ))}
            </div>
          ))}
        </div>
        {user && (
          <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
            {user ? (
              <ListItemButton onClick={handleClickLogOut}>
                <div className="icon-container">
                  <ListItemIcon>
                    <TiTimesOutline className="nav-icon" />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: "0.81rem",
                      fontFamily: "Nunito",
                      color: "#202426",
                    }}
                    variant="caption"
                    component="div"
                    className="icon-title"
                  >
                    CHECK OUT
                  </Typography>
                </div>
              </ListItemButton>
            ) : (
              <ListItem>
                <div className="icon-container">
                  <ListItemIcon>
                    <TiTimesOutline className="nav-icon" />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: "0.81rem",
                      fontFamily: "Nunito",
                      color: "##202426",
                    }}
                    variant="caption"
                    component="div"
                    className="icon-title"
                  >
                    CHECK OUT
                  </Typography>
                </div>
              </ListItem>
            )}
          </div>
        )}
        <ListItem onClick={toggleFeedbackModal}>
          <div className="icon-container">
            <ListItemIcon>
              <BsFillChatRightTextFill className="nav-icon" />
            </ListItemIcon>
            <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "0.81rem",
                  fontFamily: "Nunito",
                  color: "#202426",
                }}
                variant="caption"
                component="div"
                className="icon-title"
            >
              FEEDBACK
            </Typography>
          </div>
        </ListItem>
      </List>
      <FeedbackModal open={feedbackOpen} handleClose={toggleFeedbackModal} user={user} />
    </SwipeableDrawer>
  );

  const renderAppBar = () => (
    <header className="navbar no-print">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
        >
          <CiMenuKebab />
        </IconButton>
        {user && (
          <Lottie
            animationData={animationData}
            style={{ width: "100px", height: "100px" }}
          />
        )}
        <div className="logo-bkoffice">
          <Link to="/">bkof.house</Link>
        </div>
      </Toolbar>
    </header>
  );

  return (
    <>
      {renderAppBar()}
      {renderDrawer()}
    </>
  );
};

export default Nav;
